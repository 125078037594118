@import 'styles/config';

/**
 * ----------------------------------------
 * Options
 * ----------------------------------------
 */

$directions: (up, right, down, left);
$alignments: (right, left);

/**
 * ----------------------------------------
 * Mixins
 * ----------------------------------------
 */

@mixin set-animation($direction) {
  @if $direction == 'down' {
    animation-name: fadeInDown;
  } @else if $direction == 'up' {
    animation-name: fadeInUp;
  } @else if $direction == 'right' {
    animation-name: fadeInRight;
  } @else {
    animation-name: fadeInLeft;
  }
}

@mixin set-direction($direction) {
  @if $direction == 'down' {
    top: 100%;
  } @else if $direction == 'up' {
    bottom: 100%;
  } @else if $direction == 'right' {
    right: 100%;
  } @else {
    left: 100%;
  }
}

@mixin set-alignment($alignment) {
  @if $alignment == 'left' {
    left: 0;
  } @else if $alignment == 'right' {
    right: 0;
  }
}

/**
 * ----------------------------------------
 * Generate style
 * ----------------------------------------
 */

@each $direction in $directions {
  @each $alignment in $alignments {
    .dropdown-content-#{$direction}-#{$alignment} {
      @extend .dropdown-content;
      @include set-animation($direction);
      @include set-direction($direction);
      @include set-alignment($alignment);
      //#{$position}: 0;
    }
  }
}

/**
 * ----------------------------------------
 * Base
 * ----------------------------------------
 */

.dropdown {
  position: relative;

  &-trigger {
    cursor: pointer;
    user-select: none;
  }

  &-content {
    position: absolute;
    list-style: none;
    margin: 0;
    right: 0;
    min-width: 400px;
    background-color: $color-white;
    box-shadow: $box-shadow-lg;
    animation-duration: 0.3s;
    z-index: 100;

    &-header {
      padding: 10px;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      border-bottom: 1px solid $color-border;
      background-color: $color-gray;
    }
  }
}

/**
 * ----------------------------------------
 * Animation
 * ----------------------------------------
 */

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -15px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(15px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(-15px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
