@import 'styles/config';

.splitview {
  height: calc(100vh - 60px);
  display: flex;

  .actionpanel {
    flex: 1;
    max-width: 350px;
    height: 100%;
    background-color: $color-white;
    border-left: 2px solid $color-border;
    padding: $gutter * 2;
    overflow: auto;
  }

  .actionview {
    flex: 1;
    overflow: auto;

    &-header {
      display: flex;
      background-color: $color-gray-light;
      padding: $gutter * 2;
    }

    &-title {
      font-size: 20px;
      line-height: 60px;
    }

    &-title-icon {
      width: 60px;
      height: 60px;
      line-height: 60px;
      margin-right: $gutter;
      background-color: $color-white;
      border-radius: 5px;
      text-align: center;
      font-size: 20px;
      color: $color-primary;
      box-shadow: $box-shadow-lg;
    }

    &-body {
      padding: $gutter * 2;
    }
  }
}
