@import 'styles/config';

.navbar {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 250px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 60px;
  overflow-y: auto;
  background-color: $color-white;

  &-container {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  &-brand-img {
    height: 40px;
  }

  &-menu {
    &-container {
      position: relative;
      list-style: none;
      margin: 0 0 $gutter * 2 0;
      padding: 0;
    }

    &-sub-container {
      position: relative;
      padding: 0 0 0 $gutter * 2;
      list-style: none;

      &:before {
        content: '';
        height: 100%;
        opacity: 1;
        width: 3px;
        background: $color-blue-light;
        position: absolute;
        left: 20px;
        top: 0;
        border-radius: 15px;
      }

      .navbar-menu-link {
        padding: 0 0 0 15px;
      }
    }

    &-title {
      position: relative;
      display: block;
      line-height: 40px;
      height: 40px;
      padding: 0 25px 0 45px;
      color: $color-primary-dark;
      border-radius: $border-radius-md;
      font-weight: bold;
      text-transform: uppercase;
      user-select: none;
    }

    &-link {
      display: block;
      line-height: 40px;
      height: 40px;
      padding: 0 25px 0 45px;
      position: relative;
      border-radius: $border-radius-md;
      color: $color-text;
      white-space: nowrap;
      text-decoration: none;
      margin-bottom: 5px;

      &:hover,
      &:focus {
        text-decoration: none;
        background-color: $color-blue-light;
      }

      &:focus {
        font-weight: bold;
      }

      &.active {
        font-weight: bold;
        background-color: $color-blue-light;
      }
    }

    &-icon {
      position: absolute;
      left: 5px;
      top: 50%;
      width: 34px;
      height: 34px;
      line-height: 34px;
      margin-top: -17px;
      font-size: 25px;
      text-align: center;
      opacity: 0.3;

      &.rocket {
        transform: rotate(-45deg);
      }
    }
  }
}
