@import 'styles/config';

.inputField {
  height: 38px;
  line-height: 38px;
  box-shadow: none;
  border: 1px solid $input-border-color;
  transition: 0.3s ease all;

  &:focus,
  &:hover {
    box-shadow: $box-shadow-input;
  }
}

.successIcon {
  position: absolute;
  right: 0;
  bottom: 8px;
  right: 10px;
  color: $color-green;
}
