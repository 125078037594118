@import 'styles/config';

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  &--scrollbox {
    @extend .list;
    max-height: 450px;
    overflow-y: scroll;
  }
}

.listItem {
  padding: $gutter/2;
  border-bottom: 1px solid $color-border;

  &:hover {
    background-color: $color-border;
  }
}