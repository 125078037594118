@import 'styles/config';

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  font-family: $font-family-body, sans-serif;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading, sans-serif;
}

.flex-grow-2 {
  flex-grow: 2;
}

.c-pointer {
  cursor: pointer !important;
}

.c-default {
  cursor: default !important;
}

.o-50 {
  opacity: 0.5 !important;
}

.form-group {
  position: relative;
}

.form-control {
  font-size: inherit;
}

.divider:after {
  display: block;
  content: '';
  background: linear-gradient(
    to right,
    rgba(223, 226, 231, 0) 0%,
    #dfe2e7 10%,
    #dfe2e7 90%,
    rgba(223, 226, 231, 0) 100%
  );
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 100%;
  height: 1px;
  width: 100%;
}

.divider-vertical:after {
  @extend .divider;
  height: 100%;
  width: 1px;
  left: 100%;
  top: 0;
  background: linear-gradient(
    to bottom,
    rgba(223, 226, 231, 0) 0%,
    #dfe2e7 10%,
    #dfe2e7 90%,
    rgba(223, 226, 231, 0) 100%
  );
}

.divider-vertical-before:before {
  @extend .divider-vertical:after;
  right: 100%;
  left: 0;
}

.lock-transform {
  transform: none !important;
}

.outline-0 {
  outline: 0 !important;
}

.ql-editor {
  min-height: 100px !important;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}
