@import 'styles/config';

/**
 * ----------------------------------------
 * Color styles
 * ----------------------------------------
 */

$colors: (default, transparent, green, red, blue);

%select-transparent {
  background-color: transparent;
  color: $color-text;
}

%select-default {
  background-color: $color-gray;
  color: $color-text;
}

%select-green {
  background-color: #e9f9ec;
  color: #399a4b;
}

%select-red {
  background-color: #ffdcdc;
  color: #e65656;
}

%select-blue {
  background-color: #dcefff;
  color: #5380c3;
}

/**
 * ----------------------------------------
 * Generate style
 * ----------------------------------------
 */

@each $color in $colors {
  .select-inner-label-#{$color} {
    width: 100%;
    padding: 6px;
    font-size: 11px;
    line-height: 11px;
    border-radius: 4px;
    margin-bottom: -4px;
    @extend %select-#{$color};
  }
}
