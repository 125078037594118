@import 'styles/config';

.notification {
  padding: $gutter;
  border-bottom: 1px solid $color-border;
  display: flex;

  &-message {
    .heading {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .date {
      color: $color-text-light;
    }
  }

  &-unread {
    @extend .notification;
    background-color: $color-blue-light;
    border-color: $color-blue-medium;
  }

  &:hover {
    .notification-action {
      opacity: 1;
    }
  }

  &-action {
    opacity: 0;
    position: relative;
    cursor: pointer;
    font-size: 10px;
    padding: 5px;
    margin-left: auto;
    transition: opacity 0.2s ease;

    .tooltip {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      background: $color-text;
      color: $color-white;
      padding: 5px;
      min-width: 90px;
      text-align: center;
      border-radius: $border-radius-sm;

      &:after {
        position: absolute;
        bottom: -5px;
        left: 50%;
        margin-left: -5px;
        width: 0;
        border-top: 5px solid $color-text;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        content: ' ';
        font-size: 0;
        line-height: 0;
      }
    }

    &:hover {
      .tooltip {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
