@import 'styles/config';

$header-height: 60px;

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: $header-height;
  display: flex;
  align-items: center;
  align-content: center;
  z-index: 10;
  background-color: $color-white;
  box-shadow: $box-shadow-lg;
  transition: all 0.2s;

  &-content {
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1 1;
    padding: 0 $gutter;
    height: $header-height;
  }

  &-logo {
    padding: 0 $gutter;
    height: $header-height;
    width: 250px;
    display: flex;
    align-items: center;
  }

  &-left {
    display: flex;
    align-items: center;
  }

  &-right {
    align-items: center;
    display: flex;
    margin-left: auto;

    > div {
      padding: 0 $gutter;
      border-left: 1px solid $color-border;
    }
  }

  &-user {
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
  }
}
