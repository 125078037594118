@import 'styles/config';

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.image-compact {
  @extend .image;
  padding: 1.5em;
}

.image-container {
  height: 50px;
  width: 50px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: white;
}
