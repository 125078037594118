@import 'styles/config';

.aside {
  position: fixed;
  right: 0;
  top: 60px;
  bottom: 0;
  width: 600px;
  height: 100vh;
  background-color: $color-white;
  padding: 30px;
  z-index: 1000;
  animation: fadeInLeft;
  animation-duration: 0.3s;

  box-shadow: $box-shadow-lg;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

/**
 * ----------------------------------------
 * Animation
 * ----------------------------------------
 */

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(15px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
