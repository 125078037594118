@import 'styles/config';

/**
 * ----------------------------------------
 * Available colors
 * ----------------------------------------
 */

$colors: (default, transparent, primary, green, red, blue, yellow, disabled);

/**
 * ----------------------------------------
 * Color styles
 * ----------------------------------------
 */
%icon-wrapper-transparent {
  background-color: transparent;
  color: $color-text;
}

%icon-wrapper-default {
  background-color: $color-gray;
  color: $color-text;
}

%icon-wrapper-primary {
  background-color: $color-gray;
  color: $color-primary;
}

%icon-wrapper-green {
  background-color: $color-green-light;
  color: $color-green-dark;
}

%icon-wrapper-red {
  background-color: $color-red-light;
  color: $color-red;
}

%icon-wrapper-blue {
  background-color: $color-blue-light;
  color: $color-blue;
}

%icon-wrapper-yellow {
  background-color: $color-yellow-light;
  color: $color-yellow;
}

%icon-wrapper-disabled {
  background-color: rgba(160, 160, 160, 0.3);
  color: $color-white;
  cursor: initial;
}

/**
 * ----------------------------------------
 * Generate style
 * ----------------------------------------
 */

@each $color in $colors {
  .icon-wrapper-#{$color} {
    display: inline-block;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 20px;
    transition: background-color 0.2s ease;

    @extend %icon-wrapper-#{$color};
  }
}
