@import 'styles/config';

/**
 * ----------------------------------------
 * Options
 * ----------------------------------------
 */

$directions: (up, right, down, left);
$alignments: (right, left);

/**
 * ----------------------------------------
 * Mixins
 * ----------------------------------------
 */

@mixin set-animation($direction) {
  @if $direction == 'down' {
    animation-name: fadeInDown;
  } @else if $direction == 'up' {
    animation-name: fadeInUp;
  } @else if $direction == 'right' {
    animation-name: fadeInRight;
  } @else {
    animation-name: fadeInLeft;
  }
}

@mixin set-direction($direction) {
  @if $direction == 'down' {
    top: 100%;
  } @else if $direction == 'up' {
    bottom: 100%;
  } @else if $direction == 'right' {
    right: 100%;
  } @else {
    left: 100%;
  }
}

@mixin set-alignment($alignment) {
  @if $alignment == 'left' {
    left: 0;
  } @else if $alignment == 'right' {
    right: 0;
  }
}

/**
 * ----------------------------------------
 * Generate style
 * ----------------------------------------
 */

@each $direction in $directions {
  @each $alignment in $alignments {
    .menu-dropdown-#{$direction}-#{$alignment} {
      @extend .menu-dropdown;
      @include set-animation($direction);
      @include set-direction($direction);
      @include set-alignment($alignment);
      //#{$position}: 0;
    }
  }
}

/**
 * ----------------------------------------
 * Base
 * ----------------------------------------
 */

.menu {
  &-trigger {
    cursor: pointer;
    user-select: none;
    min-width: 21px;
  }

  &-container {
    position: relative;
  }

  &-dropdown {
    list-style: none;
    margin: 0;
    padding: $gutter/2;
    position: absolute;
    //right: 0;
    min-width: 150px;
    background-color: $color-white;
    border-radius: $border-radius-md;
    box-shadow: $box-shadow-modal;
    animation-duration: 0.3s;
    z-index: 100;

    li {
      padding: $gutter/2;
      border-radius: $border-radius-md;
      user-select: none;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: $color-blue-light;
        cursor: pointer;
      }
    }
  }
}

/**
 * ----------------------------------------
 * Animation
 * ----------------------------------------
 */

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -15px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(15px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(-15px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
