@import 'styles/config';

.article,
.article--editing {
  margin-bottom: 100px;

  &--editing {
    background-color: white;
  }

  h1 {
    font-weight: normal;
  }

  p {
    line-height: 30px;
  }

  h1,
  p {
    font-family: 'Georgia';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  .author {
    display: flex;

    .details {
      margin-left: 5px;
    }

    .date {
      font-size: 12px;
      color: $color-text-light;
    }
  }

  .description {
    padding: $gutter/2 0;
    background-color: $color-gray;
  }

  .content {
    font-size: 18px;
  }
}
