@import 'styles/config';

// Variables
$checkbox-size: 20px;
$checkbox-border: $input-border-color;
$checkbox-selected: $color-green; // $color-green ?

.checkbox:disabled {
  opacity: 0;
}

.checkbox {
  position: absolute;
  opacity: 0;

  // Text
  & + label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: $checkbox-size;
    padding-left: $checkbox-size + 10;
    font-weight: normal;
    text-transform: capitalize;

    // Checkbox
    &:before {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      content: '';
      background: transparent;
      width: $checkbox-size;
      height: $checkbox-size;

      box-shadow: inset 0 0 0 1px $checkbox-border;
      border-radius: $border-radius-md;
      transition: 0.2s ease-in-out all;
    }

    // Tick
    &:after {
      position: absolute;
      display: block;
      top: 4px;
      left: 8px;
      content: '';
      width: 5px;
      height: 10px;
      border-right: 2px solid transparent;
      border-bottom: 2px solid transparent;
      transform: rotate(45deg);

      transition: 0.2s ease-in-out all;
    }
  }

  // Hover
  & + label:hover {
    color: #333;
  }

  // Focus
  & + label:focus {
    outline: none;
  }

  // Checked
  &:checked {
    & + label {
      &:before {
        background: $checkbox-selected;
        box-shadow: none;
      }
      &:after {
        border-color: $color-white;
      }
    }
  }

  // Disabled
  &:disabled {
    & + label {
      &:before {
        background: #f2f2f2; // Disabled colour
        box-shadow: none;
      }
      &:after {
        border-color: transparent;
      }
    }
  }

  // Disabled Checked
  &:checked:disabled {
    & + label {
      &:before {
        background: #f2f2f2;
        box-shadow: none;
      }
      &:after {
        border-color: #cecece;
      }
    }
  }
}
