@import 'styles/config';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: $color-white;
  border-radius: $border-radius-md;
  padding: 0;

  box-shadow: $box-shadow-sm;
  transition: all 0.2s ease;

  &-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: $gutter;
    letter-spacing: 1px;
  }

  &-body {
    padding: $gutter * 1.5 $gutter;
  }
}
