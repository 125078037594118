// Duplicate of select-box multivalue boxes

.container {
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
}

.text {
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 85%;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.removeButton {
  align-items: center;
  border-radius: 2px;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;

  svg {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
  }

  &:hover {
    background-color: #ffbdad;
    color: #de350b;
  }
}
