@import 'styles/config';

.view {
  min-height: calc(100vh - 60px);
  margin: 60px 0 0 250px;
  background-color: $color-gray;

  &-header {
    display: flex;
    background-color: $color-gray-light;
    padding: $gutter * 2;
  }

  &-title {
    font-size: 20px;

    &--only {
      @extend .view-title;
      margin-top: 15px;
    }
  }

  &-subtitle {
    color: $color-text-light;
  }

  &-title-icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
    margin-right: $gutter;
    background-color: $color-white;
    border-radius: 5px;
    text-align: center;
    font-size: 20px;
    color: $color-primary;
    box-shadow: $box-shadow-lg;
  }

  &-body {
    background-color: $color-gray;
    padding: $gutter * 2;
  }
}
