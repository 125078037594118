@import 'styles/config';

.container {
  margin-bottom: 4px;
  &:focus {
    outline: 0;
    .node {
      border: 1px $color-primary solid;
    }
  }
}

.node {
  outline: none;
  cursor: pointer !important;
  background: white;
  line-height: 1.1em;
  user-select: none;
  border-radius: 4px;
}

.node-product {
  @extend .node;
  width: auto;
}

.selected {
  background: $color-blue-medium;
}

.multiselected {
  background-color: $color-gray-light;
}

.focus {
  background: $color-blue-medium;
  border-radius: inherit;
}

.badge {
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: $color-primary;
  line-height: 1em;
  min-width: calc(1em + 12px); // line-height + padding * 2
  padding: 6px;
  text-align: center;
  color: white;
}

.unset {
  color: $color-text-light;
}
