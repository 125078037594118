@import 'styles/config';

.searchresult {
  cursor: pointer;
  transition: background-color 0.2s ease, padding-left 0.3s ease;
  user-select: none;
  color: $color-text-light;

  &:hover,
  &--active {
    color: $color-text;
    padding-left: 5px;
    user-select: none;

    .IconWrapper {
      background-color: $color-blue-light;
    }
  }
}

.text-complete {
  color: $color-text-light;
  user-select: none;
}
