@import 'styles/config';
@import '~react-toastify/dist/ReactToastify.css';

.Toastify__toast {
  border-radius: $border-radius-md;
  font-weight: bold;
  text-align: center;

  &--success {
    background: $color-green;
  }
  &--info {
    background: $color-blue;
  }
  &--warning {
    background: $color-yellow;
  }
  &--error {
    background: $color-red;
  }
}
