@import 'styles/config';

.table {
  width: 100%;

  th,
  td {
    padding: $gutter/2;
  }

  th {
    border-bottom: 1px solid darken($color-border, 5%);
    color: $color-text-light;
  }

  td {
    border-top: 1px solid $color-border;
  }

  .push-right {
    display: flex;

    > * {
      margin-left: auto;
    }
  }
}
